<template>
  <v-bottom-navigation
    fixed
    v-if="displayNavBar"
    :elevation="0"
    mode="shift"
    v-model="activeTab"
    :style="{
      borderTop: '1px solid lightgrey',
      opacity: displayNavBar ? 1 : 0,
      pointerEvents: displayNavBar ? 'all' : 'none',
    }"
    height="48"
    mandatory="true"
  >
    <v-btn
      @click.stop="handleRouting('/posts/create', router, previousTab)"
      data-testid="bottom-navigation-tab-create"
    >
      <v-icon>mdi-plus-box-outline</v-icon>
      <span>Create</span>
    </v-btn>
    <v-btn
      @click.stop="handleRouting('/profile', router, previousTab)"
      data-testid="bottom-navigation-tab-profile"
    >
      <v-icon>mdi-home</v-icon>
      <span>Home</span>
    </v-btn>
    <v-btn
      @click.stop="handleRouting('/privateSharedPosts', router, previousTab)"
      data-testid="bottom-navigation-tab-storiesSharedToYou"
      :disabled="friendStore.acceptedFriendRequest == null"
    >
      <v-icon>mdi-message-text</v-icon>
      <span>Private</span>
    </v-btn>
  </v-bottom-navigation>
</template>

<script setup>
import { onMounted, ref, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useFriendStore } from "@/stores/friendStore";
import { handleRouting } from "@/utils/routing/handleRouting";

const componentName = "BottomNavigation";

const displayNavBar = ref(false);
const friendStore = useFriendStore();

const route = useRoute();
const router = useRouter();

const previousTab = ref(0);
const activeTab = ref(0);

const tabs = [
  {
    value: "StoryCreator",
    name: "create",
    route: "/posts/create",
  },
  {
    value: "Profile",
    name: "profile",
    route: "/profile",
  },
  {
    value: "PrivateSharedPosts",
    name: "storiesSharedToYou",
    route: "/privateSharedPosts",
  },
];

// const getTabIndexByTabName = (tabName) => {
//   const functionName = "getTabIndexByTabName";
//   console.info(componentName, functionName);
//   const tabIndex = tabs.findIndex((obj) => obj.name == tabName);
//   console.debug(componentName, functionName, "tabIndex:", tabIndex);
//   return tabIndex;
// };

const getTabIndexByTabValue = (tabValue) => {
  const functionName = "getTabIndexByTabValue";
  console.info(componentName, functionName);
  const tabIndex = tabs.findIndex((obj) => obj.value == tabValue);
  console.debug(componentName, functionName, "tabIndex:", tabIndex);
  return tabIndex;
};

const changeTabByIndex = (tabIndex) => {
  const functionName = "changeTabByIndex";
  console.info(componentName, functionName);
  console.debug(componentName, functionName, "tabIndex:", tabIndex);

  if (tabIndex > -1) {
    activeTab.value = tabIndex; // Change the active tab when clicked
  }
};

function passTabChange() {
  const functionName = "passTabChange";
  console.info(componentName, functionName);

  if (typeof activeTab.value === "string") {
    const index = getTabIndexByTabValue(activeTab.value);
    router.push(tabs[index].route);
  } else if (typeof activeTab.value === "number") {
    router.push(tabs[activeTab.value].route);
  }
}

function revertTabChange() {
  const functionName = "revertTabChange";
  console.info(componentName, functionName);

  console.debug(componentName, functionName, "previousTab:", previousTab.value);

  changeTabByIndex(previousTab.value);
}

defineExpose({ passTabChange, revertTabChange });

// Check if current route requires v-bottom-navigation to be displayed -->
watch(
  () => route.meta,
  (newMeta) => {
    console.debug(componentName, "newMeta:", newMeta);
    displayNavBar.value = newMeta.displayBotNav || false;
  },
  { flush: "pre", immediate: true, deep: false },
);
// <--

// Get current route's name so it can be automatically activated in case app is just launched in specific route -->
watch(
  () => route.name,
  (newRouteName) => {
    console.debug(componentName, "newRouteName:", newRouteName);

    switch (newRouteName) {
      case "create":
        activeTab.value = 0;
        break;
      case "profile":
        activeTab.value = 1;
        break;
      case "storiesSharedToYou":
        activeTab.value = 2;
        break;

      default:
        activeTab.value = 1;
        break;
    }
  },
  { flush: "pre", immediate: true, deep: false },
);
// <--

onMounted(async () => {
  const functionName = "onMounted";
  console.info(componentName, functionName);
});
</script>

<style>
.firebase-emulator-warning {
  background-color: transparent !important;
  border: none !important;
  color: rgba(245, 66, 66, 0.3) !important;
  pointer-events: none;
}
</style>
