<template>
  <div
    :style="{
      display: 'flex',
      flexDirection: 'column',
      alignSelf: 'center',
      alignItems: 'center',
      justifyContent: 'center',
      boxSizing: 'border-box',
      width: '100%',
      height: `${dimenStore.containerHeight}px`,
    }"
  >
    <div>
      <p
        style="
          font-size: 15px;
          font-weight: bold;
          text-align: center;
          margin-bottom: 25px;
        "
      >
        Let's give your device a name so it's easier to identify
      </p>
      <v-text-field
        class="multi-line"
        :placeholder="'iPhone 12 Mini RED'"
        :disabled="isProcessingLinking == true"
        v-model="deviceName"
        clearable
        @click:clear="clearDeviceName()"
        label="Device name"
        counter
        maxlength="50"
        :rules="[validateText]"
        type="text"
        :style="{
          width: dimenStore.isMobile ? '100%' : '450px',
          height: 'fit-content',
          marginBottom: '25px',
        }"
      ></v-text-field>
      <v-btn
        variant="tonal"
        @click="verifyLinkingDevice"
        color="primary"
        :disabled="getLinkingButtonDisabled()"
      >
        <v-icon
          style="margin-right: 10px"
          v-if="!isProcessingLinking"
          icon="mdi-link-variant"
        ></v-icon>
        {{ isProcessingLinking ? "" : "Link device" }}
        <v-progress-circular
          v-if="isProcessingLinking"
          indeterminate
        ></v-progress-circular>
      </v-btn>
    </div>
  </div>
</template>

<script setup>
import { auth, firestore } from "@/firebase"; // Import your Firebase configuration
import { deleteDoc, doc } from "firebase/firestore";
import { useAuthStore } from "@/stores/authStore";
import { useSnackBarStore } from "@/stores/snackBarStore";
import { useDimenStore } from "@/stores/dimenStore";
import getChallenge from "@/utils/access/getChallenge";
import webAuthnCreateCredential from "@/utils/access/webAuthnCreateCredential";
import { signInAnonymously } from "@firebase/auth";
import { onMounted, onUnmounted, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import linkNewDeviceToUser from "../utils/linkDevices/linkNewDeviceToUser";
import verifyDeviceLinkCode from "../utils/linkDevices/verifyDeviceLinkCode";

const componentName = "LinkDevicesVerifyView";

const dimenStore = useDimenStore();
const authStore = useAuthStore();
const snackBarStore = useSnackBarStore();
const deviceName = ref("");
const defaultDeviceLinkingInfo = ref("");
const router = useRouter();
const route = useRoute();
const isProcessingLinking = ref(false);
const linkingSuccess = ref(null);
const inputError = ref([]);

const clearDeviceName = () => {
  const functionName = "clearDeviceName";
  console.info(componentName, functionName);
  deviceName.value = "";
};

const getDeviceInfo = () => {
  const userAgent = navigator.userAgent;
  let os = "Unknown OS";
  let browser = "Unknown Browser";

  // Detect OS
  if (userAgent.indexOf("Win") !== -1) os = "Windows";
  if (userAgent.indexOf("Mac") !== -1) os = "MacOS";
  if (userAgent.indexOf("X11") !== -1) os = "UNIX";
  if (userAgent.indexOf("Linux") !== -1) os = "Linux";
  if (/Android/.test(userAgent)) os = "Android";
  if (/iPhone|iPad|iPod/.test(userAgent)) os = "iOS";

  // Detect Browser
  if (/Chrome/.test(userAgent) && /Google Inc/.test(navigator.vendor)) {
    browser = "Chrome";
  } else if (
    /Safari/.test(userAgent) &&
    /Apple Computer/.test(navigator.vendor)
  ) {
    browser = "Safari";
  } else if (/Firefox/.test(userAgent)) {
    browser = "Firefox";
  } else if (/MSIE|Trident/.test(userAgent)) {
    browser = "Internet Explorer";
  } else if (/Edge/.test(userAgent)) {
    browser = "Edge";
  } else if (/Opera|OPR/.test(userAgent)) {
    browser = "Opera";
  }

  return `${os}, ${browser}, ${new Date().toLocaleDateString()} ${new Date().toLocaleTimeString()}`;
};

const validateText = (value) => {
  const functionName = "validateText";

  if (!value || value.trim().length == 0) {
    inputError.value = [];
    return true;
  }

  let errorMessage = "";

  let regex = /^[A-Za-z]/; // Allow only letters as first character
  errorMessage = "First character has to be a letter";
  if (!regex.test(value)) {
    if (inputError.value.indexOf(errorMessage) === -1)
      inputError.value.push(errorMessage);
  } else if (inputError.value.indexOf(errorMessage) !== -1) {
    inputError.value.splice(inputError.value.indexOf(errorMessage), 1);
  }

  regex = /^.{15,50}$/; // Device name has to be specific length
  errorMessage = "Device name has to be 15-50 characters long";
  if (!regex.test(value)) {
    if (inputError.value.indexOf(errorMessage) === -1)
      inputError.value.push(errorMessage);
  } else if (inputError.value.indexOf(errorMessage) !== -1) {
    inputError.value.splice(inputError.value.indexOf(errorMessage), 1);
  }

  console.debug(componentName, functionName, "inputError: ", inputError.value);

  return inputError.value.length > 0 ? inputError.value[0] : true;
};

/**
 * This function is used for logging a detailed error message while showing user a generic one
 *
 * @param {*} errorMessage a more detailed error message to be logged
 */
const handleError = (errorMessage) => {
  const functionName = "handleError";
  console.info(componentName, functionName);

  console.debug(componentName, functionName, errorMessage);

  isProcessingLinking.value = false;

  snackBarStore.displayNotification({
    message: "Error occurred, please try again later",
    color: "error",
    timeout: 2250,
  });
};

/**
 * Using DeviceLinking code, register new credentials using WebAuthn
 * and store them in Firestore for later authentication.
 */
const verifyLinkingDevice = async () => {
  const functionName = "verifyLinkingDevice";
  console.info(componentName, functionName);

  isProcessingLinking.value = true;

  const code = route.params.code;
  console.debug(componentName, functionName, "code:", code);

  /**
   * - VerifyDeviceLinkCode returns stored userId and username from deviceLink-document
   * that matches the code.
   * - Document's ID is later used for deleting the document, freeing space in Firestore
   */
  const verifyDeviceLinkCodeResult = await verifyDeviceLinkCode(code);

  const userId = verifyDeviceLinkCodeResult.userId;
  const username = verifyDeviceLinkCodeResult.username;
  const deviceLinkingDocId = verifyDeviceLinkCodeResult.deviceLinkingDocId;

  if (!verifyDeviceLinkCodeResult.status) {
    handleError("Error occurred when fetching matching DeviceLink-document");
    return;
  }

  /**
   * Request challenge for verifying WebAuthn response later on
   */
  const response = await getChallenge(
    verifyDeviceLinkCodeResult.username,
    verifyDeviceLinkCodeResult.userId,
  );
  const challenge = response.challenge;

  if (!challenge) {
    handleError("Error occurred when fetching challenge");
    return;
  }

  const registration = await webAuthnCreateCredential(
    challenge,
    userId,
    verifyDeviceLinkCodeResult.username,
  );

  if (!registration.status) {
    handleError(registration.info);
    return;
  }

  console.debug(
    componentName,
    functionName,
    "Registration-object:",
    registration,
  );

  /**
   * Store user's WebAuthn credentials into Firestore so they
   * can be used for authentication.
   */
  const isLinkingSuccessful = await linkNewDeviceToUser(
    userId,
    registration.registration.credential,
    defaultDeviceLinkingInfo.value,
    deviceName.value,
  );

  if (!isLinkingSuccessful.status) {
    handleError(isLinkingSuccessful.info);
    return;
  }

  /**
   * Delete DeviceLink-document as it's no longer needed.
   * This is not blocking; Continue function execution even if this fails.
   */
  try {
    console.debug(componentName, functionName, "deleting DeviceLink-document");
    await deleteDoc(doc(firestore, "deviceLinks", deviceLinkingDocId));
  } catch (error) {
    console.error(componentName, functionName, "Error: ", error);
  }

  /**
   * Sign user in using Firebase.
   *
   * In case of successful login, store the previously fetched
   * userId and username in Pinia and localStorage
   */
  try {
    await signInAnonymously(auth);
    console.debug(
      componentName,
      functionName,
      "Authenticated anonymously via Firebase",
    );
    authStore.setUserId(userId);
    authStore.setUsername(username);

    isProcessingLinking.value = false;
    linkingSuccess.value = true;
  } catch (error) {
    handleError(error);
    return;
  }

  snackBarStore.displayNotification({
    message: "Device successfully linked",
    color: "success",
    timeout: 2250,
  });

  isProcessingLinking.value = false;
  router.replace("/");
};

const getLinkingButtonDisabled = () => {
  const functionName = "getLinkingButtonDisabled";
  console.info(componentName, functionName);

  const isDisabled =
    inputError.value.length > 0 ||
    deviceName?.value.length < 15 ||
    isProcessingLinking.value;

  console.debug(componentName, functionName, "is disabled:", isDisabled);

  return isDisabled;
};

onMounted(async () => {
  const functionName = "onMounted";
  console.info(componentName, functionName);

  defaultDeviceLinkingInfo.value = getDeviceInfo();

  await dimenStore.calculateContainerHeight(true, false);

  window.addEventListener("resize", async () => {
    await dimenStore.calculateContainerHeight(true, false);
  });
});

onUnmounted(() => {
  window.removeEventListener("resize", async () => {
    await dimenStore.calculateContainerHeight(true, false);
  });
});
</script>

<style scoped>
.v-input__details {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
