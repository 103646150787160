import { ref } from "vue";
import { defineStore } from "pinia";

const storeName = "pwaStore";

export const usePwaStore = defineStore({
  id: storeName,
  state: () => ({
    initialized: ref(false),
    installedApps: ref(null),
    appInstalled: ref(null),
    displayMode: ref(null),
    deferredPrompt: ref(null),
    pwaInstallable: ref(null),
    pwaInstalled: ref(null),
    displayModeStandalone: ref(null),
    serviceWorkerRegistered: ref(null),
  }),
  actions: {
    onInit() {
      const functionName = "onInit";
      console.info(storeName, functionName);

      this.beforeInstallPromptListener();
      this.standaloneModeListener();
      this.checkInstalledRelatedApps()
        .then(() => {
          if (!this.initialized) {
            this.initialized = true;
          }
        })
        .catch((error) => {
          console.error(storeName, functionName, "Error:", error);
          this.initialized = true;
        });
    },
    beforeInstallPromptListener() {
      const functionName = "beforeInstallPromptListener";
      console.info(storeName, functionName);

      window.addEventListener("beforeinstallprompt", (e) => {
        console.debug(storeName, functionName, "beforeInstallPrompt fired");

        this.pwaInstallable = true;
        // Prevent the mini-infobar from appearing on mobile
        e.preventDefault();
        // Stash the event so it can be triggered later.
        this.deferredPrompt = e;
      });
    },
    async checkInstalledRelatedApps() {
      const functionName = "checkInstalledRelatedApps";
      console.info(storeName, functionName);

      if ("getInstalledRelatedApps" in navigator) {
        try {
          const apps = await navigator.getInstalledRelatedApps();
          console.debug(storeName, functionName, "Installed apps:", apps);
          this.installedApps = apps;
        } catch (error) {
          console.error("Error checking installed related apps:", error);
        }
      } else {
        console.warn(
          "getInstalledRelatedApps is not supported in this browser.",
        );
      }
    },
    standaloneModeListener() {
      const functionName = "standaloneModeListener";
      console.info(storeName, functionName);

      const mediaQuery = window.matchMedia("(display-mode: standalone)");

      mediaQuery.addEventListener("change", () => {
        if (mediaQuery.matches) {
          console.debug(
            storeName,
            functionName,
            "The app is running in standalone mode.",
          );
          // Handle standalone mode (e.g., adjust UI)
          this.displayModeStandalone = true;
        } else {
          console.debug(
            storeName,
            functionName,
            "The app is not running in standalone mode.",
          );
          // Handle browser mode (e.g., adjust UI)
          this.displayModeStandalone = false;
        }
      });
    },
    async installPWA() {
      const functionName = "installPWA";
      console.info(storeName, functionName);

      if (this.deferredPrompt) {
        this.deferredPrompt.prompt();
        const { outcome } = await this.deferredPrompt.userChoice;
        if (outcome === "accepted") {
          console.log(storeName, functionName, "User accepted the A2HS prompt");
          this.pwaInstalled = true;
        } else {
          console.log(
            storeName,
            functionName,
            "User dismissed the A2HS prompt",
          );
          this.pwaInstalled = false;
        }
        this.deferredPrompt = null;
      } else {
        console.debug(
          storeName,
          functionName,
          "no PWA install prompt triggered",
        );
      }
    },
    handleAppInstalled(event) {
      const functionName = "handleAppInstalled";
      console.info(storeName, functionName);
      console.debug(storeName, functionName, "event:", event);
      this.appInstalled = event.returnValue;
    },
    checkIfAppInstalled() {
      const functionName = "checkIfAppInstalled";
      console.info(storeName, functionName);
      if (this.displayModeStandalone) {
        console.debug(storeName, functionName, "DisplayMode is 'standalone'");
        return true;
      } else if (this.appInstalled) {
        console.debug(
          storeName,
          functionName,
          "appinstalled-event's returnValue is TRUE",
        );
        return true;
      } else {
        console.debug(
          storeName,
          functionName,
          "App most probably not installed",
        );
        return false;
      }
    },
  },
});
