import { useAuthStore } from "@/stores/authStore";
import { useFriendStore } from "@/stores/friendStore";

const functionName = "requestStoryFromFriend";

export default async function () {
  console.info(functionName);

  const authStore = useAuthStore();
  const friendStore = useFriendStore();

  try {
    const body = {
      requesterUserId: authStore.userId,
      requesterUsername: authStore.username,
      requestedUserId: friendStore.getFriendUserId(),
      requestedUsername: friendStore.getFriendUsername(),
    };
    console.debug(functionName, `body: ${body}`);

    let response = await fetch(
      `${process.env.VUE_APP_FIRESTORE_FUNCTIONS}/requestStory`,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json", // Specify that you are sending JSON data
        },
        body: JSON.stringify(body),
      },
    );
    return { status: response.status, statusText: response.statusText };
  } catch (error) {
    console.error(functionName, "Error:", error);
    return false;
  }
}
