<template>
  <Sortable
    class="sortable-list"
    :list="storyPages"
    item-key="id"
    tag="div"
    :options="options"
    @choose="(event) => console.log('X: ', event)"
    @start="cancelLongPress"
    @end="handleStoryPageDragNDrop"
    :style="{
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'flex-end',
      justifyContent: `${dimenStore.isMobile ? 'flex-start' : 'center'}`,
      height: '100%',
      width: '100%',
      overflowX: 'auto',
      overflowY: 'hidden',
      gap: '10px',
      paddingInlineStart: '5px',
      paddingInlineEnd: '5px',
      whitespace: 'nowrap',
    }"
    draggable="draggable"
  >
    <template #item="{ element: storyPage, index }">
      <div
        v-if="storyPage.isAddStoryPageButton"
        style="
          display: flex;
          flex-shrink: 0;
          align-items: center;
          justify-content: center;
          width: 100px;
          height: 100px;
        "
      >
        <v-btn
          data-testid="add-story-page-button"
          size="large"
          class="rounded-btn"
          color="secondary"
          ripple
          flat
          @click="
            () => {
              newStoryStore.addStoryPage();
            }
          "
          icon="mdi-plus"
          variant="text"
          style="color: black; background-color: transparent; flex-shrink: 0"
        >
        </v-btn>
      </div>
      <div
        v-else
        class="draggable"
        :key="storyPage.id"
        @click="
          () => {
            !deleteStoryPagesToggleMode &&
              props.updateCurrentStoryPageIndex(index);
          }
        "
        :class="`horizontal-scroll-item#${storyPage.id}`"
        :style="{
          position: 'relative',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          overflow: 'auto',
          flexShrink: '0',
        }"
      >
        <v-btn
          :data-testid="`story-page-button-bottom#${storyPage.id}`"
          style="height: 100%; width: 100%; padding: 0"
          @mousedown="(event) => handleLongPress(event, index)"
          @touchstart="(event) => handleLongPress(event, index)"
          @mouseup="
            () => {
              !deleteStoryPagesToggleMode &&
                props.updateCurrentStoryPageIndex(index);
              deleteStoryPagesToggleMode && handleLongPress(event, index);
            }
          "
          @mouseleave="cancelLongPress"
          @touchend="cancelLongPress"
          :ref="(el) => (storyPageButtonRefs[index] = el)"
        >
          <div
            :data-testid="`story-page-delete-button#${storyPage.id}`"
            :style="{
              position: 'absolute',
              height: '100%',
              width: '100%',
              zIndex: '99',
              backgroundColor: '#adb5bdbf',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              visibility: `${
                deleteStoryPagesToggleMode ? 'visible' : 'hidden'
              }`,
              pointerEvents: `${deleteStoryPagesToggleMode ? 'auto' : 'none'}`,
            }"
            @click.stop="handleRemoveStoryPage(storyPage.id, index)"
          >
            <v-btn
              flat
              style="
                display: flex;
                align-items: center;
                justify-content: center;
                text-align: center;
                background-color: transparent;
              "
            >
              <v-icon
                icon="mdi-trash-can"
                size="x-large"
                :color="errorColor"
              ></v-icon>
            </v-btn>
          </div>
          <v-img
            v-if="storyPage.media && storyPage.media.url"
            :data-testid="`story-media-bottom-img#${index}`"
            :src="storyPage.media.url"
            lazy-src="https://picsum.photos/id/11/100/60"
            width="100px"
            height="100px"
            @error="mediaLoaded[index] = false"
            @load="mediaLoaded[index] = true"
            style="overflow: hidden"
          />
          <v-sheet
            v-else
            :class="`story-page-${props.getStoryPageBackgroundNumberByStoryPageId(
              storyPage.id,
            )}`"
            width="100px"
            height="100px"
            rounded
            style="display: flex; align-items: center; justify-content: center"
          >
            <v-icon icon="mdi-image-area"></v-icon>
          </v-sheet>
        </v-btn>
      </div>
    </template>
  </Sortable>
</template>

<script setup>
import { Sortable } from "sortablejs-vue3";
import { useTheme } from "vuetify";
import {
  defineProps,
  onMounted,
  onBeforeUnmount,
  ref,
  computed,
  watch,
} from "vue";
import { useNewStoryStore } from "@/stores/newStoryStore";
import { useDimenStore } from "@/stores/dimenStore";

const componentName = "BottomHorizontalStoryPages";

const dimenStore = useDimenStore();

const props = defineProps({
  isEditMode: Boolean,
  storyPages: Array,
  currentStoryPageIndex: Number,
  updateCurrentStoryPageIndex: Function,
  handleStoryPageDragNDrop: Function,
  reservedStoryPageBackgroundNumbers: Array,
  removeStoryPageBackgroundNumber: Function,
  getStoryPageBackgroundNumberByStoryPageId: Function,
});

const deleteStoryPagesToggleMode = ref(false);
const storyPageButtonRefs = ref(Array(5).fill(null));

const mediaLoaded = ref(Array(5).fill(null));

const storyPages = computed(() => {
  console.debug(componentName, "story pages count:", props.storyPages?.length);
  if (!props.isEditMode && props.storyPages.length < 5) {
    // Add a button to add a new story page
    return [...props.storyPages, { isAddStoryPageButton: true }];
  } else {
    return [...props.storyPages];
  }
});

watch(
  () => [props.storyPages, deleteStoryPagesToggleMode.value],

  // eslint-disable-next-line
  ([newStoryPages, newDeleteStoryPagesToggleMode]) => {
    if (newStoryPages.length == 1 && newDeleteStoryPagesToggleMode) {
      deleteStoryPagesToggleMode.value = false;
    }
  },
);

const newStoryStore = useNewStoryStore();

const vTheme = useTheme();
const errorColor = vTheme.current.value.colors.error;

const options = ref({
  horizontal: true,
  delayOnTouchOnly: true,
  delay: 500,
});

let longPressTimeout = null;
// eslint-disable-next-line
const handleLongPress = (event, index) => {
  const functionName = "handleLongPress";
  console.info(componentName, functionName);

  props.storyPages.forEach((storyPage) => {
    console.debug(componentName, functionName, "storyPage:", storyPage);
  });

  if (props.isEditMode) {
    console.debug(
      componentName,
      functionName,
      "Cannot delete story pages in edit mode",
    );
    return;
  }

  if (props.storyPages?.length == 1) {
    console.debug(
      componentName,
      functionName,
      "Cannot delete the last story page",
    );
    return;
  } else if (deleteStoryPagesToggleMode.value) {
    console.debug(
      componentName,
      functionName,
      "Delete mode already toggled on",
    );
    return;
  }

  console.debug(
    componentName,
    functionName,
    "Story delete mode can be toggled on",
  );
  longPressTimeout = setTimeout(() => {
    console.debug(componentName, functionName, "Toggling delete mode on");
    deleteStoryPagesToggleMode.value = true;
  }, 1500);
};

const cancelLongPress = () => {
  const functionName = "cancelLongPress";
  console.info(componentName, functionName);

  clearTimeout(longPressTimeout);
  longPressTimeout = null;
};

const handleClickOutside = (event) => {
  const functionName = "handleClickOutside";
  console.info(componentName, functionName);
  console.debug(componentName, functionName, "event.target: ", event.target);

  cancelLongPress();

  let isClickOutside = true;
  storyPageButtonRefs.value.forEach((ref) => {
    if (ref?.$el?.contains(event.target)) {
      isClickOutside = false;
      return;
    }
  });

  if (isClickOutside) {
    // Handle the click outside logic here
    console.log(
      componentName,
      functionName,
      "Clicked outside of the specified elements",
    );
    deleteStoryPagesToggleMode.value = false;
  } else {
    console.log(
      componentName,
      functionName,
      "Clicked inside of the specified elements",
    );
  }
};

const handleRemoveStoryPage = (storyPageId, storyPageIndex) => {
  const functionName = "handleRemoveStoryPage";
  console.info(componentName, functionName);

  console.debug(
    componentName,
    functionName,
    "story pages count:",
    props.storyPages?.length,
  );

  if (props.storyPages.length > 1) {
    newStoryStore.deleteStoryPage(storyPageId);
    props.removeStoryPageBackgroundNumber(storyPageId);
    const updatedStoryPageIndex = storyPageIndex > 0 ? storyPageIndex - 1 : 0;
    if (props.currentStoryPageIndex == storyPageIndex) {
      // Update the current story page only index if the current story page is removed
      props.updateCurrentStoryPageIndex(updatedStoryPageIndex);
    }
  }
};

// Preserve state across HMR updates
if (module.hot) {
  module.hot.accept();
  module.hot.dispose((data) => {
    data.deleteStoryPagesToggleMode = deleteStoryPagesToggleMode.value;
  });
  if (module.hot.data) {
    deleteStoryPagesToggleMode.value = module.hot.data.props = { ...props };
    module.hot.data.deleteStoryPagesToggleMode;
  }
}

onMounted(() => {
  document.body.addEventListener("click", handleClickOutside);

  if (process.env.NODE_ENV === "development") {
    window.BottomHorizontalStoryPages = {
      handleRemoveStoryPage: handleRemoveStoryPage,
      deleteStoryPagesToggleMode: deleteStoryPagesToggleMode,
      mediaLoaded: mediaLoaded,
    };
  }
});

onBeforeUnmount(() => {
  document.body.removeEventListener("click", handleClickOutside);
});
</script>

<style
  lang="css"
  scoped
  src="./storyCreatorViewStoryPageBackgrounds.css"
></style>
