<template>
  <v-speed-dial
    class="disable-border"
    location="bottom center"
    transition="fade-transition"
    v-model="dialOpen"
  >
    <template v-slot:activator="{ props: activatorProps }">
      <v-btn
        data-testid="story-shared-speed-dial"
        v-bind="activatorProps"
        :icon="speedDialButtons[props.sharedStatus]?.icon"
        flat
      ></v-btn>
    </template>
    <v-btn
      v-for="(speedDialButton, index) in speedDialButtons"
      :data-testid="`story-shared-status-button-${speedDialButton.sharedStatusName}`"
      :key="speedDialButton.id"
      :style="{
        backgroundColor: props.sharedStatus == index ? primaryColor : '',
      }"
      :disabled="speedDialButton.disabled"
      @click="speedDialButton.action"
      :icon="speedDialButton.icon"
      :value="index"
    />
  </v-speed-dial>
</template>

<script setup>
import { ref, watch } from "vue";
import { useTheme } from "vuetify";
import { defineProps } from "vue";
import { useNewStoryStore } from "@/stores/newStoryStore";
import { useEditStoryStore } from "@/stores/editStoryStore";
import { useFriendStore } from "@/stores/friendStore";

const props = defineProps({
  isEditMode: Boolean,
  sharedStatus: Number,
});

const dialOpen = ref(false);

const newStoryStore = useNewStoryStore();
const editStoryStore = useEditStoryStore();
const friendStore = useFriendStore();

const vTheme = useTheme();
const primaryColor = vTheme.current.value.colors.primary + "BF";
const speedDialButtons = ref([
  {
    sharedStatusName: "private",
    id: "speed-dial-button-0",
    icon: "mdi-lock",
    style: {
      backgroundColor: props.sharedStatus == 0 ? primaryColor : "",
    },
    action: () => {
      if (props.isEditMode) {
        editStoryStore.sharedStatus = 0;
      } else {
        newStoryStore.sharedStatus = 0;
      }
    },
    disabled: false,
  },
  {
    sharedStatusName: "public",
    id: "speed-dial-button-1",
    icon: "mdi-earth",
    style: {
      backgroundColor: props.sharedStatus == 1 ? primaryColor : "",
    },
    action: () => {
      if (props.isEditMode) {
        editStoryStore.sharedStatus = 1;
      } else {
        newStoryStore.sharedStatus = 1;
      }
    },
    disabled: false,
  },
  {
    sharedStatusName: "privatelyShared",
    id: "speed-dial-button-2",
    icon: "mdi-account-multiple",
    action: () => {
      if (props.isEditMode) {
        editStoryStore.sharedStatus = 2;
      } else {
        newStoryStore.sharedStatus = 2;
      }
    },
    disabled: true,
  },
]);

watch(
  () => friendStore.acceptedFriendRequest,
  (newAcceptedFriendRequest) => {
    console.debug("newAcceptedFriendRequest:", newAcceptedFriendRequest);
    if (newAcceptedFriendRequest) {
      console.debug("privatelyShared-button set to ENABLED");
      speedDialButtons.value[2].disabled = false;
    } else {
      console.debug("privatelyShared-button set to DISABLED");
      speedDialButtons.value[2].disabled = true;
    }
  },
  { immediate: true, deep: true },
);
</script>

<style scoped>
.disable-border {
  border: none !important;
  box-shadow: none !important;
}
</style>
