<template>
  <div
    class="developer-menu"
    :style="{
      display: 'flex',
      flexDirection: 'column',
      position: 'absolute',
      zIndex: 1000,
      bottom: 0,
      left: 0,
      margin: '5px',
      visibility: `${isDevEnv && !isLoggedIn ? 'visible' : 'hidden'}`,
      pointerEvents: `${isDevEnv && !isLoggedIn ? 'auto' : 'none'}`,
    }"
  >
    <v-btn
      color="#adb5bd"
      @click="overlay = !overlay"
      icon="mdi-developer-board"
    />
  </div>
  <v-dialog v-model="overlay" max-width="500px">
    <v-card>
      <v-card-title class="headline">Developer Menu</v-card-title>
      <v-card-text>
        <v-text-field
          v-model="testUsername"
          label="Test username"
          outlined
          dense
          placeholder="testuser1"
        ></v-text-field>
        <v-text-field
          v-model="testUid"
          label="Test user ID"
          outlined
          dense
          :placeholder="testUid"
        ></v-text-field>
        <div
          style="
            display: flex;
            flex-direction: row;
            margin-top: 10px;
            gap: 10px;
          "
        >
          <v-btn
            color="primary"
            @click="
              () => {
                testUid = uuidv4();
              }
            "
            icon="mdi-refresh"
            aria-label="Generate new user ID"
          >
            <v-icon icon="mdi-refresh"></v-icon>
          </v-btn>
          <v-btn
            :disabled="!isAuthStoreReady"
            color="primary"
            @click="simulatedAnonymousLogin"
            icon="mdi-login"
            aria-label="Simulate anonymous login"
          >
            <v-icon icon="mdi-login"></v-icon>
          </v-btn>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="grey" text @click="overlay = false">Close</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script setup>
import { ref, computed } from "vue";
import { v4 as uuidv4 } from "uuid";
import { useAuthStore } from "@/stores/authStore";
import { auth } from "@/firebase";
import { signInAnonymously } from "@firebase/auth";

const authStore = useAuthStore();

const isDevEnv = process?.env?.NODE_ENV === "development";
const isLoggedIn = computed(() => {
  return authStore.user && authStore.userId && authStore.username;
});
const overlay = ref(false);

const testUsername = ref(process.env.VUE_APP_AUTH_MOCK_USERNAME ?? "");
const testUid = ref(process.env.VUE_APP_AUTH_MOCK_USERID ?? uuidv4());

const isAuthStoreReady = computed(() => {
  return authStore.initialized;
});

const simulatedAnonymousLogin = async () => {
  authStore.setUserId(testUid.value);
  authStore.setUsername(testUsername.value);
  authStore.setUser({});
  try {
    await signInAnonymously(auth);
  } catch (error) {
    console.error("Error signing in anonymously", error);
  }
  overlay.value = false;
};
</script>
