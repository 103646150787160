import { defineStore } from "pinia";
import { ref } from "vue";
import { useRouter } from "vue-router";

const storeName = "dimenStore";

export const useDimenStore = defineStore({
  id: storeName,
  state: () => ({
    vContainerPadding: ref(null),
    vBotNavHeight: ref(null),
    vTopNavHeight: ref(null),
    isMobile: ref(null),
    containerHeight: null,
  }),
  actions: {
    initStore() {
      const functionName = "initStore";
      console.info(storeName, functionName);

      this.calculateIsMobile();
      this.calculateContainerDynamicHeight();

      const router = useRouter();
      router.afterEach((to, from) => {
        console.log(storeName, "Route changed from:", from, "to:", to);
        this.calculateIsMobile();
        this.calculateContainerDynamicHeight(to.meta);
      });
    },
    calculateContainerDynamicHeight(routeMeta) {
      const functionName = "calculateContainerDynamicHeight";
      console.info(storeName, functionName);
      console.debug(storeName, functionName, "routeMeta:", routeMeta);

      let totalNavHeight = 0;

      if (routeMeta?.displayTopNav) {
        totalNavHeight += 48;
      }

      if (routeMeta?.displayBotNav) {
        totalNavHeight += 48;
      }

      this.containerHeight = window.innerHeight - totalNavHeight;
    },
    setVContainerPadding(padding) {
      const functionName = "setVContainerPadding";
      this.vContainerPadding = padding;
      console.debug(
        storeName,
        functionName,
        "vContainerPadding: ",
        this.vContainerPadding,
      );
    },
    setVBotNavHeight(height) {
      const functionName = "setVBotNavHeight";
      this.vBotNavHeight = height;
      console.debug(
        storeName,
        functionName,
        "vBotNavHeight: ",
        this.vBotNavHeight,
      );
    },
    setVTopNavHeight(height) {
      const functionName = "setVTopNavHeight";
      this.vTopNavHeight = height;
      console.debug(
        storeName,
        functionName,
        "vTopNavHeight: ",
        this.vTopNavHeight,
      );
    },
    calculateTopNavHeight() {
      const functionName = "calculateTopNavHeight";
      console.info(storeName, functionName);

      return new Promise((resolve, reject) => {
        let appBarInterval = setInterval(() => {
          try {
            const element =
              document.getElementsByClassName("v-toolbar__content")[0];
            if (!(element instanceof Element)) return;
            const computedStyle = window.getComputedStyle(element);
            const appBarHeight = parseInt(computedStyle.height);
            console.debug(
              storeName,
              functionName,
              "app bar's height:",
              appBarHeight,
            );
            this.vTopNavHeight = appBarHeight;

            // Clear the interval once the height is obtained
            clearInterval(appBarInterval);
            appBarInterval = null;

            resolve(appBarHeight);
          } catch (error) {
            console.warn(storeName, functionName, error);
            reject(error);
          }
        }, 250);
      });
    },
    calculateIsMobile() {
      const functionName = "setIsMobile";
      console.info(storeName, functionName);

      this.isMobile = window.innerWidth <= 600;

      console.debug(storeName, functionName, "isMobile:", this.isMobile);
    },
    calculateBottomNavHeight() {
      const functionName = "calculateBottomNavHeight";
      console.info(storeName, functionName);

      return new Promise((resolve, reject) => {
        let bottomNavigationInterval = setInterval(() => {
          try {
            const element = document.getElementsByClassName(
              "v-bottom-navigation",
            )[0];
            if (!(element instanceof Element)) return;
            const computedStyle = window.getComputedStyle(element);
            const bottomNavigationHeight = parseInt(computedStyle.height);
            console.debug(
              storeName,
              functionName,
              "bottom navigation's height:",
              bottomNavigationHeight,
            );
            this.vBotNavHeight = bottomNavigationHeight;

            // Clear the interval once the height is obtained
            clearInterval(bottomNavigationInterval);
            bottomNavigationInterval = null;

            resolve(bottomNavigationHeight);
          } catch (error) {
            console.warn(storeName, functionName, error);
            reject(error);
          }
        }, 500);
      });
    },
    async calculateContainerHeight(isTopNavActive, isBotNavActive) {
      const functionName = "calculateContainerHeight";
      console.info(storeName, functionName);

      let containerHeight = window.innerHeight;
      console.debug(
        storeName,
        functionName,
        "containerHeight before substractions:",
        containerHeight,
      );

      if (isTopNavActive) {
        try {
          const topNavHeight = await this.calculateTopNavHeight();
          containerHeight -= topNavHeight;
        } catch (error) {
          console.warn(storeName, functionName, error);
        }
      }

      if (isBotNavActive) {
        this.calculateBottomNavHeight();
        try {
          const botNavHeight = await this.calculateBottomNavHeight();
          containerHeight -= botNavHeight;
        } catch (error) {
          console.warn(storeName, functionName, error);
        }
      }

      console.debug(
        storeName,
        functionName,
        "containerHeight:",
        containerHeight,
      );

      this.containerHeight = containerHeight;

      return containerHeight;
    },
  },
});
