import { initializeApp } from "firebase/app";
import { connectAuthEmulator, getAuth } from "firebase/auth";
import { connectFirestoreEmulator, getFirestore } from "firebase/firestore";
// import { connectFunctionsEmulator, getFunctions } from "firebase/functions";
import { getMessaging, isSupported } from "firebase/messaging";

const fileName = "firebase.js";

const firebaseConfig = {
  apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
  authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGE_SENDER_ID,
  appId: process.env.VUE_APP_FIREBASE_APP_ID,
  measurementId: process.env.VUE_APP_FIREBASE_MEASUREMENT_ID,
};

export const firebaseApp = initializeApp(firebaseConfig);

const getFirestoreInstance = () => {
  if (
    window?.location?.hostname === "localhost" ||
    window?.location?.hostname === "www.storyque.app"
  ) {
    return "";
  } else if (window?.location?.hostname === "www.storyque.xyz") {
    return "storyque-dev";
  }
};

const firestoreInstanceName = getFirestoreInstance();

// Export Firebase services
export const auth = getAuth();
export const firestore = getFirestore(firestoreInstanceName);
let messaging = null;
let messagingReady = new Promise((resolve) => {
  isSupported()
    .then((supported) => {
      if (supported) {
        messaging = getMessaging();
        resolve(messaging);
      } else {
        resolve(null);
      }
    })
    .catch((error) => {
      console.error(fileName, "messagingReady error:", error);
      resolve(null);
    });
});

export { messaging, messagingReady };

console.debug(fileName, "process.env.NODE_ENV: ", process.env.NODE_ENV);

const isAutomatedTesting = window.automatedTesting;
console.debug(fileName, "isAutomatedTesting:", isAutomatedTesting);

// Use emulators during development
if (process.env.NODE_ENV === "development") {
  console.debug(fileName, "using Firebase Emulator Suite");
  connectFirestoreEmulator(
    firestore,
    "192.168.3.33",
    isAutomatedTesting ? 8083 : 8080,
  );
  connectAuthEmulator(
    auth,
    `http://192.168.3.33:${isAutomatedTesting ? "9100" : "9099"}`,
    {
      disableWarnings: true,
    },
  );
  // connectFunctionsEmulator(functions, "localhost", functionsEmulatorPort);
} else {
  console.debug(
    fileName,
    `using '${firestoreInstanceName}' Firestore instance`,
  );
}
