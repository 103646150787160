async function requestOtp(email) {
  const functionName = "requestOtp";
  console.info(functionName);

  try {
    let response = await fetch(
      `${process.env.VUE_APP_FIRESTORE_FUNCTIONS}/getOtp`,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json", // Specify that you are sending JSON data
        },
        body: JSON.stringify({ email: email }),
      },
    );
    return { status: response.status, statusText: response.statusText };
  } catch (error) {
    console.error(functionName, "Error:", error);
    return false;
  }
}

export default requestOtp;
