<template>
  <v-app-bar app :elevation="2" rounded density="compact">
    <template v-slot:prepend>
      <v-app-bar-nav-icon @click="goBack" data-testid="back-button">
        <v-icon>mdi-arrow-left</v-icon>
      </v-app-bar-nav-icon>
      <v-app-bar-title style="font-weight: bold">Storyque</v-app-bar-title>
    </template>
    <template v-slot:append>
      <StoryShareStatusDial
        :isEditMode="isEditMode"
        :sharedStatus="sharedStatus"
      />
      <v-app-bar-nav-icon
        data-testid="post-or-update-button"
        color="primary"
        :disabled="isStoryPostable == false"
        @click="
          async () => {
            if (route.name == 'storyEditor') {
              await editStoryStore.updateStory();
              goBack();
            } else {
              await newStoryStore.postStory();
            }
          }
        "
      >
        <v-icon
          v-if="
            isEditMode
              ? editStoryStore.isPosting == false
              : newStoryStore.posting == false
          "
          >{{
            route.name == "storyEditor" ? "mdi-content-save-edit" : "mdi-send"
          }}</v-icon
        >
        <v-progress-circular v-else color="primary" indeterminate />
      </v-app-bar-nav-icon>
    </template>
  </v-app-bar>
  <div
    data-testid="story-creator-view"
    class="d-flex justify-center"
    :style="{
      height: `${dimenStore.containerHeight}px`,
      boxSizing: 'border-box',
    }"
  >
    <div
      style="
        display: flex;
        flex-direction: column;
        width: 100%;
        align-items: center;
      "
    >
      <div
        class="media-container"
        style="
          display: flex;
          flex: 1;
          align-items: center;
          justify-content: center;
          height: 100%;
          width: 100%;
        "
      >
        <v-btn
          :class="`${
            !storyPages[currentStoryPageIndex]?.media?.valid &&
            `story-page-${getStoryPageBackgroundNumberByStoryPageId(
              storyPages[currentStoryPageIndex].id,
            )}`
          }`"
          :style="{
            margin: '10px',
            alignItems: 'center',
            padding: '0',
            width: dimenStore.isMobile ? '95%' : '500px',
            height: dimenStore.isMobile ? '95%' : '500px',
            borderRadius: '10px',
            overflow: 'hidden',
          }"
          elevation="0"
          @click="handleBottomSheet(storyPages[currentStoryPageIndex].id)"
        >
          <v-img
            v-if="
              storyPages[currentStoryPageIndex]?.media?.valid &&
              storyPages[currentStoryPageIndex]?.media?.url
            "
            :data-testid="`story-media-main-img#${storyPages[currentStoryPageIndex].id}`"
            :src="storyPages[currentStoryPageIndex].media.url"
            lazy-src="https://picsum.photos/id/11/100/60"
            @error="
              storyStore.setUrlValidity(
                storyPages[currentStoryPageIndex].id,
                false,
              )
            "
            @load="
              storyStore.setUrlValidity(
                storyPages[currentStoryPageIndex].id,
                true,
              )
            "
            :style="{
              boxSizing: 'border-box',
              backgroundColor: '#e9ecef',
              borderRadius: '10px',
              width: dimenStore.isMobile
                ? `${calculateWidthPercentage(95)}px`
                : '500px',
            }"
            inline
          />
          <v-sheet
            v-else
            :data-testid="`story-media-main-img-placeholder#${storyPages[currentStoryPageIndex].id}`"
            rounded
            :style="{
              width: dimenStore.isMobile ? '95%' : '500px',
              height: dimenStore.isMobile ? '95%' : '500px',
              boxSizing: 'border-box',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              margin: '0px',
              backgroundColor: 'transparent',
            }"
          >
            <v-icon icon="mdi-image-area" size="x-large"></v-icon>
          </v-sheet>
        </v-btn>
      </div>
      <div class="text-container">
        <v-skeleton-loader
          v-if="storyPagesLoaded == false"
          type="paragraph"
        ></v-skeleton-loader>
        <v-textarea
          v-else
          :data-testid="`story-text-input-field#${storyPages[currentStoryPageIndex]?.id}`"
          v-model="storyPages[currentStoryPageIndex].text"
          placeholder="One beautiful day.."
          clearable
          persistent-clear
          @click:clear="
            storyStore.resetStoryPageText(storyPages[currentStoryPageIndex].id);
            handleBlur();
          "
          @focus="handleFocus"
          @blur="handleBlur"
          label="Story text"
          variant="outlined"
          counter
          maxlength="1000"
          max-width="1000px"
          :rules="[validateStoryPageText]"
        ></v-textarea>
      </div>
      <div
        class="bottom-container"
        style="
          display: flex;
          flex-direction: row;
          height: 100px;
          width: 100%;
          align-items: center;
        "
      >
        <BottomHorizontalStoryPages
          :isEditMode="isEditMode"
          :storyPages="storyPages"
          :currentStoryPageIndex="currentStoryPageIndex"
          :updateCurrentStoryPageIndex="updateCurrentStoryPageIndex"
          :handleStoryPageDragNDrop="handleStoryPageDragNDrop"
          :reservedStoryPageBackgroundNumbers="
            reservedStoryPageBackgroundNumbers
          "
          :removeStoryPageBackgroundNumber="removeStoryPageBackgroundNumber"
          :getStoryPageBackgroundNumberByStoryPageId="
            getStoryPageBackgroundNumberByStoryPageId
          "
        />
      </div>
    </div>
  </div>
  <v-bottom-sheet
    data-testid="storyCreatorView-bottom-sheet"
    v-model="isBottomSheetVisible.open"
    @input="handlebottomSheetInput"
  >
    <v-sheet style="padding: 5px">
      <p style="padding: 5px">Select media from..</p>
      <v-btn
        v-for="(button, index) in bottomSheetOptions"
        :data-testid="`bottom-sheet-action#${index}`"
        :key="button.id"
        @click="button.action"
        :variant="button.variant"
        :color="button.color"
        style="width: 100%"
      >
        {{ button.label }}
      </v-btn>
    </v-sheet>
  </v-bottom-sheet>
</template>

<script setup>
/* eslint-disable no-unused-vars */
import { useDimenStore } from "@/stores/dimenStore";
import { useNewStoryStore } from "../stores/newStoryStore";
import {
  onMounted,
  onUnmounted,
  computed,
  ref,
  watch,
  onBeforeMount,
} from "vue";
import { useRouter, useRoute } from "vue-router";
import { useFriendStore } from "@/stores/friendStore";
import { useEditStoryStore } from "@/stores/editStoryStore";
import { useMyStoriesStore } from "@/stores/myStoriesStore";
import { useSnackBarStore } from "@/stores/snackBarStore";
import { handleRouting } from "@/utils/routing/handleRouting";
import StoryShareStatusDial from "@/components/storyCreator/StoryShareStatusDial.vue";
import BottomHorizontalStoryPages from "@/components/storyCreator/BottomHorizontalStoryPages.vue";

const componentName = "StoryCreatorView";

const currentStoryPageIndex = ref(0);
function updateCurrentStoryPageIndex(newIndex) {
  currentStoryPageIndex.value = newIndex;
}
const route = useRoute();

const editStoryStore = useEditStoryStore();

const isEditMode = ref(route.params?.postId !== undefined);
watch(
  () => isEditMode.value,
  (newIsEditModeState) => {
    if (newIsEditModeState) {
      console.debug(componentName, "isEditMode:", newIsEditModeState);
      console.debug(componentName, "postId in URL:", route.params.postId);
      editStoryStore.populateStoryToBeEdited(route.params.postId);
    }
  },
  { immediate: false, deep: false },
);

const dimenStore = useDimenStore();
const router = useRouter();
const newStoryStore = useNewStoryStore();
const isBottomSheetVisible = ref({ open: false, targetStoryPageId: null });

watch(
  () => isBottomSheetVisible.value,
  (newIsBottomSheetVisible) => {
    Object.keys(newIsBottomSheetVisible).forEach((key) => {
      console.log(
        componentName,
        `newIsBottomSheetVisible, key: ${key}, value: ${newIsBottomSheetVisible[key]}`,
      );
    });
  },
  { immediate: false, deep: false },
);

const friendStore = useFriendStore();
const bottomSheetOptions = [
  {
    id: 0,
    label: "Paste link",
    color: "black",
    variant: "outline",
    action: async () => {
      await pasteFromClipboard(isBottomSheetVisible.value.targetStoryPageId);
      await newStoryStore.checkGifUrl(
        isBottomSheetVisible.value.targetStoryPageId,
      );
      isBottomSheetVisible.value = {
        open: false,
        targetStoryPageId: null,
      };
    },
  },
  {
    id: 1,
    label: "Reset",
    color: "error",
    variant: "text",
    action: () => {
      newStoryStore.resetStoryPageMedia(
        isBottomSheetVisible.value.targetStoryPageId,
      );
      isBottomSheetVisible.value = {
        open: false,
        targetStoryPageId: null,
      };
    },
  },
];

const myStoriesStore = useMyStoriesStore();
const snackBarStore = useSnackBarStore();

const sharedStatus = computed(() => {
  return isEditMode.value
    ? editStoryStore.sharedStatus
    : newStoryStore.sharedStatus;
});
watch(
  sharedStatus,
  (newSharedStatus) => {
    console.debug(componentName, "sharedStatus changed:", newSharedStatus);
  },
  { immediate: true, deep: false },
);

const goBack = () => {
  handleRouting("/", router, null);
};

const handleBottomSheet = async (storyPageId) => {
  const functionName = "handleBottomSheet";
  console.info(componentName, functionName);
  console.debug(componentName, functionName, "storyPageId:", storyPageId);

  isBottomSheetVisible.value = {
    open: true,
    targetStoryPageId: storyPageId,
  };
};

// Handle closing BottomSheet, nullifying the temporary storing of selected story page's ID
const handlebottomSheetInput = (isOpen) => {
  const functionName = "handlebottomSheetInput";
  console.info(componentName, functionName);
  console.debug(componentName, functionName, "isOpen:", isOpen);

  if (!isOpen && isBottomSheetVisible.value.open !== false) {
    isBottomSheetVisible.value = {
      open: false,
      targetStoryPageId: null,
    };
  }
};

const pasteFromClipboard = async (storyPageId) => {
  const functionName = "pasteFromClipboard";
  console.info(componentName, functionName);

  const index = isEditMode.value
    ? editStoryStore.getPageIndexById(storyPageId)
    : newStoryStore.getPageIndexById(storyPageId);
  try {
    const link = await navigator.clipboard.readText();
    console.log(
      componentName,
      functionName,
      "index:",
      index,
      "clipboard:",
      link,
    );
    isEditMode.value
      ? (editStoryStore.pages[index].media.url = link)
      : (newStoryStore.pages[index].media.url = link);
  } catch (error) {
    console.error(error);
  }
};

const validateStoryPageText = (value) => {
  if (value == undefined) {
    return;
  }
  if (value.length == 0) {
    return "Story text is required";
  }

  if (value.trim().length == 0) {
    return true;
  }

  if (value.length < 20) {
    return "Story text too short";
  }

  return true;
};

const extractSharedStatusFromUrl = () => {
  const functionName = "extractSharedStatusFromUrl";
  console.info(componentName, functionName);

  const fullUrl = window.location;
  console.debug(componentName, functionName, "fullUrl:", fullUrl);

  const url = new URL(fullUrl);
  const pathname = url.pathname;
  console.debug(componentName, functionName, "pathname:", pathname);

  const segments = pathname.split("/").filter((segment) => segment !== "");

  let sharedStatus;
  try {
    sharedStatus = Number(segments[2]);
  } catch (error) {
    console.error(componentName, functionName, "sharedStatus not a number");
    sharedStatus = 0;
  }

  console.debug(componentName, functionName, `sharedStatus: ${sharedStatus}`);

  if ([0, 1, 2].includes(sharedStatus)) {
    if (sharedStatus == 2 && friendStore.acceptedFriendRequest == null) {
      console.debug(
        componentName,
        functionName,
        "trying to privately share without a friend; Defaulting to private",
      );
      sharedStatus = 0; // Default to private story
    } else {
      console.debug(
        componentName,
        functionName,
        `using number '${sharedStatus}' provided in URL for sharedStatus`,
      );
      return sharedStatus;
    }
  } else {
    sharedStatus = 0; // Default to private story
    console.debug(
      componentName,
      functionName,
      `defaulting to number '${sharedStatus}' for sharedStatus`,
    );
    return sharedStatus;
  }
};

const storyPagesLoaded = computed(() => {
  return isEditMode.value
    ? editStoryStore.pages.length != 0
    : newStoryStore.pages.length != 0;
});

const storyStore = computed(() => {
  return isEditMode.value ? editStoryStore : newStoryStore;
});

const storyPages = computed(() => {
  const tempStoryPages = [...storyStore.value.pages];
  tempStoryPages.forEach((storyPage) => {
    console.debug(componentName, "storyPage:", storyPage.id);
    if (storyPage?.number === undefined)
      storyPage.number = getStoryPageBackgroundNumber(storyPage.id);
  });
  return tempStoryPages;
});

const handleStoryPageDragNDrop = computed(() => {
  return isEditMode.value
    ? editStoryStore.updateStoryPageOrderAfterDragNDrop
    : newStoryStore.updateStoryPageOrderAfterDragNDrop;
});

const isStoryPostable = computed(() => {
  if (isEditMode.value) {
    return editStoryStore.isStoryEdited && editStoryStore.validStory;
  } else {
    return newStoryStore.pages.some(
      (storyPage) => storyPage.media.valid && storyPage?.text.length >= 20,
    );
  }
});

// For selecting custom background graphics for each story page (re-use backgrounds when a story page is deleted and new one added) -->
const storyPageBackgroundNumbers = [0, 1, 2, 3, 4];
const reservedStoryPageBackgroundNumbers = ref([]);

const getStoryPageBackgroundNumber = (storyPageId) => {
  const functionName = "getStoryPageBackgroundNumber";
  console.info(componentName, functionName);

  let availableNumber = null;
  for (let number of storyPageBackgroundNumbers) {
    if (
      !reservedStoryPageBackgroundNumbers.value.some(
        (item) => item.number === number,
      )
    ) {
      console.debug(componentName, functionName, "Available number:", number);
      // Keep track of the reserved number
      reservedStoryPageBackgroundNumbers.value.push({
        storyPageId: storyPageId,
        number: number,
      });

      // Return the available number
      availableNumber = number;
      break;
    } else {
      console.debug(
        componentName,
        functionName,
        "Number already reserved:",
        number,
      );
    }
  }

  return availableNumber;
};

const removeStoryPageBackgroundNumber = (storyPageId) => {
  const functionName = "removeStoryPageBackgroundNumber";
  console.info(componentName, functionName);

  const reservedNumberIndex =
    reservedStoryPageBackgroundNumbers.value.findIndex(
      (item) => item.storyPageId === storyPageId,
    );
  console.debug(
    componentName,
    functionName,
    "reservedNumberIndex:",
    reservedNumberIndex,
  );
  if (reservedNumberIndex !== -1) {
    const reservedNumber = reservedStoryPageBackgroundNumbers.value.splice(
      reservedNumberIndex,
      1,
    )[0];
    console.debug(
      componentName,
      functionName,
      "Removed reserved number:",
      reservedNumber,
    );
  } else {
    console.debug(
      componentName,
      functionName,
      "No reserved number found for storyPageId:",
      storyPageId,
    );
  }
};

const getStoryPageBackgroundNumberByStoryPageId = (storyPageId) => {
  const functionName = "getStoryPageBackgroundNumberByStoryPageId";
  console.info(componentName, functionName);

  const reservedNumber = reservedStoryPageBackgroundNumbers.value.find(
    (item) => item.storyPageId === storyPageId,
  )?.number;
  if (reservedNumber !== undefined) {
    console.debug(
      componentName,
      functionName,
      "Reserved number:",
      reservedNumber,
    );
    return reservedNumber;
  } else {
    console.debug(
      componentName,
      functionName,
      "No reserved number found for storyPageId:",
      storyPageId,
    );
    return null;
  }
};

const calculateWidthPercentage = (percentage) => {
  return (window.innerWidth * percentage) / 100;
};

const calculateHeightPercentage = (percentage) => {
  return (window.innerHeight * percentage) / 100;
};

// Preserve state across HMR updates
if (module.hot) {
  module.hot.accept();
  module.hot.dispose((data) => {
    data.storyPages = storyPages.value;
    data.currentStoryPageIndex = currentStoryPageIndex.value;
  });
  if (module.hot.data) {
    storyPages.value = module.hot.data.storyPages;
    currentStoryPageIndex.value = module.hot.data.currentStoryPageIndex;
  }
}

onBeforeMount(() => {
  console.info(componentName, "onBeforeMount");
  if (isEditMode.value) {
    const storyId = route.params.postId;
    console.debug(componentName, "storyId in URL:", storyId);
    // To-be edited story's ID in URL
    if (myStoriesStore.getStoryIndexById(storyId) != -1) {
      console.debug(componentName, "in edit-mode");
      editStoryStore.populateStoryToBeEdited(storyId);
      editStoryStore.checkStoryValidity();
    } else {
      // Invalid storyId; Not existing or user's own story
      snackBarStore.displayNotification({
        message: "Invalid story",
        color: "error",
        timeout: 2250,
      });
      router.replace("/");
    }
  } else {
    newStoryStore.createStory(extractSharedStatusFromUrl());
    newStoryStore.storySharedStatusListener();
    newStoryStore.checkStoryValidity();
  }
});

const handleFocus = function () {
  console.debug(componentName, "inputElement focus");
  document.body.style.position = "";
};

const handleBlur = function () {
  console.debug(componentName, "inputElement blur");
  document.body.style.position = "fixed";
};

// Preserve state across HMR updates
if (module.hot) {
  module.hot.accept();
  module.hot.dispose((data) => {
    data.storyPages = storyPages.value;
  });
  if (module.hot.data) {
    storyPages.value = module.hot.data.storyPages;
  }
}

onMounted(async () => {
  console.info(componentName, "onMounted");
  if (process.env.NODE_ENV === "development") {
    window.StoryCreatorView = {
      currentStoryPageIndex: currentStoryPageIndex,
      reservedStoryPageBackgroundNumbers: reservedStoryPageBackgroundNumbers,
    };
  }

  dimenStore.calculateIsMobile();
  await dimenStore.calculateContainerHeight(true, false);

  window.addEventListener("resize", async () => {
    await dimenStore.calculateContainerHeight(true, false);
  });
});

onUnmounted(() => {
  if (isEditMode.value) {
    editStoryStore.reset();
  } else {
    newStoryStore.deleteStory();
  }

  window.removeEventListener("resize", async () => {
    await dimenStore.calculateContainerHeight(true, false);
  });
});
</script>

<style scoped>
p br::after {
  content: "\A"; /* Using a generated content with '\A' for a newline */
  white-space: pre; /* Preserve whitespace to ensure the newline is rendered */
}

.create-story-pages-list {
  width: 500px;
}

.v-card {
  text-align: left;
}

.v-card-item__content {
  padding-top: 10px;
}

.v-input {
  margin-bottom: 10px;
}

.panel-header {
  background-color: #f0f0f0;
  border: none;
  color: #333;
  cursor: pointer;
  font-weight: bold;
  padding: 10px;
  width: 100%;
  text-align: left;
}

.panel-content {
  display: none;
  padding: 10px;
}

.horizontal-scroll-item {
  align-self: center;
  justify-content: center;
  height: 100%;
  margin-right: 25px;
  border-radius: 10px;
  overflow: hidden;
}

.img-with-margin {
  margin: 10px; /* Adjust margin as needed */
  width: 500px; /* Subtract the horizontal margins from the width */
  height: 500px; /* Subtract the vertical margins from the height */
  box-sizing: border-box; /* Include padding and border in the element's total width and height */
}

.bottom-container {
  flex: 0.5;
  margin-bottom: 10px;
  margin-top: 10px;
  width: 100%; /* Subtract the horizontal margins from the width */
  height: calc(100% - 20px); /* Subtract the vertical margins from the height */
  box-sizing: border-box; /* Include padding and border in the element's total width and height */
}

.text-container {
  flex: 1;
  margin: 10px; /* Adjust margin as needed */
  width: calc(100% - 20px); /* Subtract the horizontal margins from the width */
  height: calc(100% - 20px); /* Subtract the vertical margins from the height */
  box-sizing: border-box; /* Include padding and border in the element's total width and height */
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
</style>

<style
  lang="css"
  scoped
  src="../components/storyCreator/storyCreatorViewStoryPageBackgrounds.css"
></style>
