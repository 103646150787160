async function verifyOtp(email, otp) {
  const functionName = "verifyOtp";
  console.info(functionName);
  console.debug(functionName, `email: '${email}, otp: '${otp}'`);

  try {
    let response = await fetch(
      `${process.env.VUE_APP_FIRESTORE_FUNCTIONS}/verifyOtp`,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json", // Specify that you are sending JSON data
        },
        body: JSON.stringify({ email: email, otp: otp }),
      },
    );
    const data = await response.json();
    return {
      status: response.status,
      statusText: response.statusText,
      data: data,
    };
  } catch (error) {
    console.error(functionName, "Error:", error);
    return false;
  }
}

export default verifyOtp;
