import { usePwaStore } from "./stores/pwaStore";

export default function registerServiceWorker() {
  const functionName = "registerServiceWorker";
  console.info(functionName);

  if ("serviceWorker" in navigator) {
    navigator.serviceWorker
      .register("/firebase-messaging-sw.js")
      .then((registration) => {
        console.debug(
          functionName,
          "Service worker registered with scope:",
          registration.scope,
        );

        // Check if the page is already controlled by the service worker
        if (navigator.serviceWorker.controller) {
          console.debug(
            functionName,
            "Service worker is already controlling the page.",
          );
        } else {
          console.debug(
            functionName,
            "Service worker is not controlling the page yet.",
          );

          // Listen for when the service worker starts controlling the page
          navigator.serviceWorker.addEventListener("controllerchange", () => {
            console.log(
              functionName,
              "Service worker is now controlling the page.",
            );
            // Optionally reload the page
            // window.location.reload();
          });
        }

        // Wait until the service worker is fully activated and ready
        // return navigator.serviceWorker.ready;

        const pwaStore = usePwaStore();
        pwaStore.serviceWorkerRegistered = true;
      });
  } else {
    console.warn(
      functionName,
      "Service workers are not supported in this browser.",
    );
  }
}
