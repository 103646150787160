import { firestore, messaging, messagingReady } from "@/firebase";
import {
  addDoc,
  collection,
  getDocs,
  setDoc,
  limit,
  query,
  where,
  doc,
} from "firebase/firestore";
import { getToken } from "firebase/messaging";
import { defineStore } from "pinia";
import { ref, watch } from "vue";
import { useAuthStore } from "./authStore";
import { useSnackBarStore } from "./snackBarStore";
import { useNotificationStore } from "./notificationStore";
import { usePwaStore } from "./pwaStore";
import isIOS from "@/utils/isIOS";
import { isSupported } from "firebase/messaging";

const storeName = "fcmStore";

/**
 * This Pinia store is for handling Firebase Cloud Messaging by registering service worker in following scope: /firebase-cloud-messaging-push-scope
 * and by fetching FCM-token for the current device. Token is used for sending push-data to the device.
 */

export const useFcmStore = defineStore({
  id: storeName,
  state: () => ({
    fcmToken: null,
    fcmTokenRefreshListener: ref(null),
    authStore: useAuthStore(),
    notificationStore: useNotificationStore(),
    pwaStore: usePwaStore(),
    snackBarStore: useSnackBarStore(),
    notificationSupported: ref(isSupported()),
    notificationPermissionGranted: ref(null),
    permissionCheckInterval: null,
    permissionCheckIntervalMs: 2500,
    messaging: ref(null),
  }),
  actions: {
    async onInit() {
      const functionName = "onInit";
      console.info(storeName, functionName);

      watch(
        () => [this.authStore.user, this.notificationSupported],
        ([newUser, newNotificationSupported]) => {
          if (newUser && newNotificationSupported) {
            this.pollNotificationPermissionChanges();
          } else if (this.permissionCheckInterval) {
            clearInterval(this.permissionCheckInterval);
            this.permissionCheckInterval = null;
          }
        },
        { immediate: true },
      );

      // Wait for user to authenticate via Firebase before fetching FCM-token for receiving push-notifications
      this.messaging = await messagingReady;

      watch(
        () => [
          this.authStore.userId,
          this.notificationPermissionGranted,
          this.notificationSupported,
          this.fcmToken,
          this.messaging,
        ],
        async ([
          newUserId,
          newNotificationPermissionGranted,
          newNotificationSupported,
          newFcmToken,
          messaging,
        ]) => {
          console.info(
            storeName,
            functionName,
            "newUserId:",
            newUserId,
            "newNotificationPermissionGranted:",
            newNotificationPermissionGranted,
            "newNotificationSupported:",
            newNotificationSupported,
            "newFcmToken:",
            newFcmToken,
            "messaging:",
            messaging,
          );
          if ((newUserId && newNotificationSupported, messaging)) {
            if (newNotificationPermissionGranted == true) {
              console.debug(storeName, functionName, "User logged in");

              if (!newFcmToken) {
                console.error(
                  storeName,
                  functionName,
                  "no FCM-token fetched yet, fetching now",
                );
                this.fcmToken = await this.getFcmToken();
              } else {
                console.debug(storeName, functionName, "FCM-token fetched");
              }

              try {
                await this.uploadFcmTokenToFirestore(this.fcmToken);
              } catch (error) {
                console.debug(storeName, functionName, "Error: ", error);
              }
            } else if (newNotificationPermissionGranted == null) {
              // Create notifications permission request notification
              this.notificationStore.saveNotification({
                title: "Notification permission",
                notificationType: "requestNotificationPermission",
              });
            }
          }
        },
        { immediate: true },
      );
    },
    convertNotificationPermission(notificationPermission) {
      switch (notificationPermission) {
        case "default":
          return undefined;
        case "granted":
          return true;
        case "denied":
          return false;
        default:
          return "default";
      }
    },
    pollNotificationPermissionChanges() {
      // const functionName = "pollNotificationPermissionChanges";
      this.permissionCheckInterval = setInterval(() => {
        if (typeof Notification === "undefined") {
          console.error("Notification API is not supported in this browser.");
          clearInterval(this.permissionCheckInterval);
          this.permissionCheckInterval = null;
          return;
        }

        const convertedNotificationPermission =
          this.convertNotificationPermission(Notification.permission);
        // console.debug(
        //   functionName,
        //   "Notification.permission:",
        //   Notification.permission,
        //   "this.notificationPermissionGranted:",
        //   this.notificationPermissionGranted,
        // );
        if (
          this.notificationPermissionGranted !== convertedNotificationPermission
        ) {
          this.notificationPermissionGranted = convertedNotificationPermission;
        }

        // Remove notification permission prompt from in-app notifications
        if (
          this.notificationPermissionGranted &&
          this.notificationStore.checkIfNotificationTypeExist(
            "requestNotificationPermission",
          )
        ) {
          this.notificationStore.removeNotification(
            "requestNotificationPermission",
          );
        }
      }, this.permissionCheckIntervalMs);
    },
    getNotificationPermission() {
      const functionName = "getNotificationPermission";
      console.info(storeName, functionName);

      if (!("Notification" in window)) {
        console.error(
          storeName,
          functionName,
          "This browser doesn't support notifications",
        );
        this.notificationSupported = false;
        console.debug(
          storeName,
          functionName,
          "this.notificationSupported:",
          this.notificationSupported,
        );
        return;
      } else {
        this.notificationSupported = true;
      }

      const notificationPermission = Notification.permission;
      console.debug(
        storeName,
        functionName,
        "Notification permission:",
        notificationPermission,
      );
      if (notificationPermission === "granted") {
        this.notificationPermissionGranted = true;
      } else if (notificationPermission === "denied") {
        this.notificationPermissionGranted = false;
      } else if (notificationPermission === "default") {
        this.notificationPermissionGranted = undefined; // User has not decided yet
      }

      // Handle adding notification to iOS users based on PWA's installation status
      if (isIOS()) {
        if (
          this.pwaStore.checkIfAppInstalled() &&
          (this.notificationPermissionGranted == undefined ||
            this.notificationPermissionGranted == false)
        ) {
          // App installed but notification permission not granted or rejected, request user to give notification permission
          this.notificationStore.saveNotification({
            title: "Notification permission",
            notificationType: "requestNotificationPermission",
          });
        } else if (
          this.pwaStore.checkIfAppInstalled() &&
          this.notificationPermissionGranted == true
        ) {
          // App installed and notification permission granted
          this.notificationStore.removeNotification(
            "requestNotificationPermission",
          );
        }
      }
    },
    async requestNotificationPermission() {
      const functionName = "requestNotificationPermission";
      console.info(storeName, functionName);

      if (!this.notificationSupported) {
        return;
      }

      try {
        const permission = await Notification.requestPermission();
        if (permission === "granted") {
          console.debug(
            storeName,
            functionName,
            "Notification permission granted",
          );
          this.notificationPermissionGranted = true;
        } else if (permission === "denied") {
          console.debug(
            storeName,
            functionName,
            "Notification permission refused",
          );
          this.notificationPermissionGranted = false;
        } else if (permission === "default") {
          console.debug(
            storeName,
            functionName,
            "Notification permission undecided",
          );
        }
      } catch (error) {
        console.error(
          storeName,
          functionName,
          "Failed to request notification permission",
          error,
        );
      }
    },
    async uploadFcmTokenToFirestore(fcmToken) {
      const functionName = "uploadFcmTokenToFirestore";
      console.info(storeName, functionName);

      if (!this.authStore.userId) {
        return;
      }

      const fcmTokensCollectionRef = collection(firestore, "fcmTokens");

      try {
        const q = query(
          collection(firestore, "fcmTokens"),
          where("userId", "==", this.authStore.userId),
          limit(1),
        );
        const querySnapshot = await getDocs(q);

        if (!querySnapshot.empty) {
          // Update existing FCM-token document for the userId
          console.debug(
            storeName,
            functionName,
            `An FCM-token document already exists with given userId: '${this.authStore.userId}'. Updating existing document.`,
          );

          // Update document with new timestamp and FCM-token
          const docSnapshot = querySnapshot.docs[0];
          let docData = docSnapshot.data();

          if (docData.fcmToken == fcmToken) {
            console.debug(
              storeName,
              functionName,
              `Identical FCM-token '${fcmToken}' already exists in FCM-token document for userId '${this.authStore.userId}'. Not updating it.`,
            );
            return true;
          } else {
            console.debug(
              storeName,
              functionName,
              `FCM-token '${fcmToken}' is different than the one already stored ('${docData.fcmToken}') for userId '${this.authStore.userId}'. Updating FCM-token.`,
            );
            docData.fcmToken = fcmToken;
          }
          docData.createdAt = new Date().toUTCString();
          docData.fcmToken = fcmToken;

          const docRef = doc(firestore, "fcmTokens", docSnapshot.id);

          // Merge with existing document
          await setDoc(docRef, docData, { merge: true });
          console.debug(
            storeName,
            functionName,
            `FCM-token document (with id '${docSnapshot.id}') successfully updated`,
          );
        } else {
          // Create new FCM-token document because none exist yet for the userId
          const docData = {
            userId: this.authStore.userId,
            fcmToken: fcmToken,
            createdAt: new Date().toUTCString(),
          };

          console.debug(
            storeName,
            functionName,
            "FCM-token document: ",
            docData,
          );
          // Write new FCM-token document to Firestore
          const docRef = await addDoc(fcmTokensCollectionRef, docData);
          console.debug(
            storeName,
            functionName,
            `New FCM-token document for userId '${this.authStore.userId}' created with id '${docRef.id}'`,
          );
        }
      } catch (error) {
        console.error(storeName, functionName, "Error:", error);
        return false;
      }
    },
    async getFcmToken() {
      const functionName = "getFcmToken";
      console.info(storeName, functionName);

      if (!(await navigator.serviceWorker.ready)) {
        console.error(
          storeName,
          functionName,
          "Service Worker is not ready or messaging is not initialized",
        );
        return;
      }

      try {
        const currentToken = await getToken(messaging, {
          vapidKey: process.env.VUE_APP_VAPID_KEY,
        });
        if (!currentToken) {
          console.debug(
            storeName,
            functionName,
            "No registration token available. Request permission to generate one",
          );
          return null;
        } else {
          return currentToken;
        }
      } catch (error) {
        console.error(
          storeName,
          functionName,
          "An error occurred while retrieving token:",
          error,
        );
        return null;
      }
    },
    fcmTokenRefreshListener() {
      const functionName = "fcmTokenRefreshListener";
      // Listen for token refresh events
      messaging.onTokenRefresh(() => {
        messaging
          .getToken()
          .then((refreshedToken) => {
            console.log(
              storeName,
              functionName,
              "Token refreshed:",
              refreshedToken,
            );
            this.uploadFcmTokenToFirestore(refreshedToken);
            // Send the refreshed token to your server or update it in your application state
          })
          .catch((error) => {
            console.error(
              storeName,
              functionName,
              "Unable to retrieve refreshed token:",
              error,
            );
          });
      });
    },
  },
});
