process.env.NODE_TLS_REJECT_UNAUTHORIZED = "0";

const functionName = "getChallenge";

export default async function getChallenge() {
  console.info(functionName);

  try {
    let response = await fetch(
      `${process.env.VUE_APP_FIRESTORE_FUNCTIONS}/getChallenge`,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json", // Specify that you are sending JSON data
        },
      },
    );
    if (response.ok) {
      response = await response.json();
      console.debug(functionName, "response:", response);
      return response;
    } else {
      console.error(functionName, "response:", response);
      return false;
    }
  } catch (error) {
    console.error(functionName, "error:", error);
    return null;
  }
}
